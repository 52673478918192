import React from "react"
import Layout from "../components/layout"

const UmPage = () => {
    return (
        <Layout>        
            <h2>Hvat er Kiwanis?</h2>
            <p>
            Kiwanis er ein altjóðafelagsskapur av sjálvbodnum, við tí stavnhaldi at stuðla teimum, ið tørva hjálp.
            
            Eyðkennisorðini hjá Kiwanis eru:
            </p>
            <ul>
                <li>Børnini fyrst og fremst</li>
                <li>Hjálpi heimsins børnum</li>
                <li>Tænasta til børn</li>
            </ul>
            <p>Kiwanis leggur áherðslu á mannavirði og at styrkja og menna felagsskapin.</p>
            
            <h2>Endamálið hjá Kiwanisfelagskapinum.</h2>
            <ul>
                <li>At meta andalig og menniskjalig virði hægri enn verðslig virði.</li>
                <li>At stuðla upp undir at dagligt lív verður soleiðis: “At tað sum tit vilja, at menn skulu gera móti tykkum, tað skulu tit eisini gera móti teimum.</li>
                <li>Royna at bøta um samskiftið fólkanna millum.</li>
                <li>Við at stovna Kiwanisfeløg verður grundarlag lagt undir verandi vinskap og samstundis undir eitt betri samfelag</li>
                <li>Gjøgnum Kiwanis at arbeiða saman fyri at styrkja alment álit, rættvísi og sunnari felagsskap.</li>
                <li>Gjøgnum samstarv at skapa og varðveita eina sanna, almenna hugsan, eitt ósjálvsøkni, ið ger tað møguligt at fremja rættferð, rættartrygd eins og altjóða og tjóðskaparliga fatan  og góðvilja. Harumframt at vera trúgvur móti landi sínum, tá ið tað viðurkennir frælsi hins einstaka.</li>
            </ul>                
        </Layout>      
    )
}

export default UmPage
